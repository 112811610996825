import Axios from "axios";
import JSON2FormData from "json-form-data";
import resolveURL from "@/utils/baseurl";

const axios = Axios.create({
  baseURL: resolveURL("/api/common")
});

export function sendCaptcha(data) {
  const headers = { "Content-Type": "multipart/form-data" };
  return axios.post("/valid/code", JSON2FormData(data), { headers });
}

export function uploadFile(file, filename) {
  const formData = new FormData();
  formData.append("file", file, filename);
  // const headers = { "Content-Type": "multipart/form-data" };
  // 注意接口地址...不知道为毛后端又给了个奇怪的接口前缀，暂时放在 common api 中罢了
  return axios.post("../attach/upload", formData);
}

export default axios;
