<template>
  <img
    src="@/assets/images/nexadata_logo.svg"
    :style="{ width: width, cursor: 'pointer', animation: 'antFadeIn 1s' }"
    @click="openOfficial"
  />
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: "230px"
    }
  },
  methods: {
    openOfficial() {
      window.open("https://www.nexadata.cn/");
    }
  }
};
</script>
