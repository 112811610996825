<template>
  <div class="ndl-flex">
    <a-input
      :value="value"
      @input="$emit('input', $event.target.value)"
      size="large"
      placeholder="验证码"
      class="ndl-flex-item-grow ndl-margin-right"
    >
      <i slot="prefix" class="ndl-icon-message-square"></i>
    </a-input>
    <a-button size="large" @click="onClick()" :disabled="buttonDisabled">
      {{ buttonText }}{{ countDown ? `(${countDown}s)` : "" }}
    </a-button>
  </div>
</template>

<script>
import { sendCaptcha } from "@/api/common";

export default {
  props: {
    username: String,
    value: [String, Number],
    type: {
      type: String,
      default: "mail"
    }
  },
  data() {
    return { countDown: 0, buttonText: "发送验证码" };
  },
  computed: {
    buttonDisabled() {
      return !this.username || !!this.countDown;
    }
  },
  watch: {
    username() {
      clearInterval(this.__timer);
      this.countDown = 0;
    }
  },
  methods: {
    async onClick() {
      const { username, type } = this;
      this.buttonText = "重新发送";
      this.countDown = 60;
      const timer = setInterval(() => {
        this.countDown -= 1;
        if (!this.countDown) clearInterval(timer);
      }, 1000);
      this.__timer = timer;
      sendCaptcha({ type, username })
        .then(res => this.$emit("on-send", res))
        .catch(() => {
          this.countDown = 0;
          clearInterval(timer);
        });
    }
  }
};
</script>

<style lang="less" scoped>
.ant-input-affix-wrapper {
  /deep/ input::-webkit-outer-spin-button,
  /deep/ input::-webkit-inner-spin-button {
    /* display: none; Crashes Chrome on hover */
    -webkit-appearance: none;
    /* Apparently some margin are still there even though it's hidden */
    margin: 0;
  }
  /deep/ input[type="number"] {
    /* Firefox */
    -moz-appearance: textfield;
  }
}
</style>
